<template>
  <meta name="referrer" content="no-referrer">
  <div style="position: absolute;z-index: 0;">
    <img src="../../assets/images/home-bg.png" alt="" />
  </div>
  <div class="home">
    <div class="swiper" style="margin-top: 10%;">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(v, i) in bannerPic" :key="i">
          <img :src="v.thumbnail" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-notice-bar :scrollable="true" mode="link" style="height: 30px;font-size: 15px;" background='#181a20'
      color='rgb(234, 236, 239)' @click="goTo('/user/message')">
      {{title.text_title}}
    </van-notice-bar>
    <div style="position: absolute;">
      <div class="df module p30 mt20" style="border-radius: 10px;background-color: #1e2329;width: 85%;margin-left: 1%;">
        <div class="df fdc aic" @click="goTo('/home/pool')">
          <img src="../../assets/images/xg/pool.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">權益礦池</div>
        </div>
        <div class="df fdc aic" @click="goTo('/user/team')">
          <img src="../../assets/images/xg/team.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">社區協作</div>
        </div>
        <div class="df fdc aic" @click="goTo('/user/invitation')">
          <img src="../../assets/images/xg/xg-4.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">邀請好友</div>
        </div>
        <div class="df fdc aic" @click="goTo('/home/RealTime')">
          <img src="../../assets/images/xg/cloud.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">數據雲</div>
        </div>
        <div class="df fdc aic" @click="showToast">
          <img src="../../assets/images/xg/NFT.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">跨鏈服務</div>
        </div>
        <div class="df fdc aic" @click="showToast">
          <img src="../../assets/images/xg/gamee.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">鏈游</div>
        </div>
        <div class="df fdc aic" @click="goTo('/home/per')">
          <img src="../../assets/images/xg/shop.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">鏈上優選</div>
        </div>
        <div class="df fdc aic" @click="goTo('/message/index')">
          <img src="../../assets/images/xg/message.png" style="width: 30px;height: 30px;" />
          <div class="fz28  text-black">幣鏈論壇</div>
        </div>

      </div>
      <div style="height: 265px;">
      <div style="margin-top: 5%;">
        <van-row>
          <van-col span="8" class="dis spansty">24H交易量</van-col>
          <van-col span="8" class="dis spansty">最新價</van-col>
          <van-col span="8" class="dis spansty">24H漲跌幅</van-col>
        </van-row>
      </div>
      <div style="padding: 10px;" v-for="(item, index) in coindata" :key="index">
        <van-row>
          <van-col span="8" class="dis spansty">
            <img :src="item.img" style="width: 31px;" alt="">
            <div style="margin-left: 10px;">
              <p style="font-size: 16px;text-black;">{{ item.currency_pair }}</p>
              <p> Vol {{ item.base_volume }}</p>
            </div>
          </van-col>
          <van-col span="8" class="dis spansty">{{ item.last }}</van-col>
          <van-col span="8" class="dis spansty">
            <span class="dis"
              :class="{ percentagesty: item.change_percentage > 0, percentagestytwo: item.change_percentage < 0 }">
              {{ item.change_percentage }}%
            </span>
          </van-col>
        </van-row>
        <div class="line"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Banner from "@/components/Banner";
  import Noticebar from "@/components/Noticebar";
  import Signed from "@/components/Signed";
  import {
    Toast,
    Divider
  } from "vant";
  import {
    useStore
  } from "vuex";
  import {
    mapActions,
    mapState,
    mapGetters,
    mapMutations
  } from "vuex";
  import {
    getHomeBanner,
    getGate,
    getMessageList
  } from "@/utils/api.js";
  import img1 from '../../assets/images/xg/002.png'
  import img2 from '../../assets/images/xg/003.png'
  import {
    getShopList
  } from "../../utils/api";
  import axios from 'axios';
  import img99 from '../../assets/images/bnb.png'
  import img100 from '../../assets/images/ETH.png'
  import img101 from '../../assets/images/BTC.png'

  export default {
    name: "Home",
    components: {
      Banner,
      Noticebar,
      Signed,
    },
    data() {
      return {
        show: false,
        config: {},
        tabsActive: "1",
        stockList: [],
        fundList: [],
        msgList: [],
        searchFor: "",
        page: 1,
        title: [],
        page_size: 10,
        shopList: [],
        hotShop: [],
        recommendShop: [],
        bannerPic: [],
        totalElements: '',
        classify: [

        ],
        boxss: [{
            title: '荔枝'
          },
          {
            title: '香蕉'
          },
          {
            title: '1元起'
          },
          {
            title: '酒水饮料'
          },
          {
            title: '冷冻食品'
          },
        ],
        active: '0',
        coindata: []
      };
    },
    computed: {

    },
    created() {
      this.fetchTickers();

    },
    methods: {


      formatNumber(number) {
        if (typeof number === 'string') {
          number = parseFloat(number);
        }

        if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'k';
        } else {
          return number.toString();
        }
      },
      fetchTickers() {
        try {
          getGate().then((response) => {
            console.log(response);
            const filteredData = response.data.filter(item => {
              return item.currency_pair === "ETH_USDT" || item.currency_pair === "BTC_USDT" ||
                item.currency_pair === "BNB_USDT";
            });
            console.log(filteredData);
            this.coindata = filteredData.map(item => {
              return {
                ...item,
                img: item.currency_pair === 'ETH_USDT' ? img100 : item.currency_pair === 'BNB_USDT' ? img99 :
                  img101,
                base_volume: this.formatNumber(item.base_volume)
              }
            })
            console.log(this.coindata, '代笔数据');
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },
      goTo(to) {
        this.$router.push(to);
      },
      dialog() {
        this.$toast("敬请期待");
      },
      // 搜索
      SearchFor() {
        console.log("搜索");
      },
      goShopCar() {
        this.$router.push("/shopList");
      },
      //购物车
      shopCar() {
        this.$router.push("/shopCar");
      },
      showToast() {
        Toast({
          message: '盡请期待',
          icon: 'success',
          className: 'noticeWidth'
        });
      },
      goShopDetail(n) {
        this.$router.push({
          name: "shopCarGoods",
          query: {
            id: n,
          },
        });
      },
      getlist(pagnub) {
        getShopListMsgReq({
          page: pagnub,
          page_size: this.page_size
        }).then(async (res) => {
          if ((await res.code) === 200) {
            this.shopList = res.data.list;
            this.totalElements = res.data.count;
            var hotShops = res.data.list.filter((v) => v.is_hot == 2);
            var recommendShops = res.data.list.filter((v) => v.is_hot == 1);
            this.hotShop.push(...hotShops)
            this.recommendShop.push(...recommendShops)
            console.log(this.hotShop, this.recommendShop)
          }
        });
      },
      handleScroll(e) {
        const allTotal = this.page * this.page_size
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
          if (allTotal < this.totalElements) {
            this.page++
            Toast.loading({
              message: '加载中...',
              forbidClick: true,
              loadingType: 'spinner',
              className: 'noticeWidth'
            });
            this.getlist(this.page)
          } else {
            Toast({
              message: '已加载全部内容',
              icon: 'success',
              className: 'noticeWidth'
            });
          }
        }
      },
      //商品分类
      getShopccList() {
        CommodityManagementCClass({}).then((res) => {
          console.log(res, '741');
          if (res.code == 200) {
            this.classify = res.data
          }
        })
      },
      //点击商品分类下商品跳转商品详情
      product(data) {
        // this.handleGoods(1,data)
        console.log(data, '[[[[]]]]');
        this.$router.push({
          name: "goodDetail",
          query: {
            id: data.id,
            buy_actual_amount: data.trade_price,
            trade_name: data.trade_name,
            shop_home_img: data.shop_home_img,
            buy_quantity: 1,
            judge: this.$route.path,
          },
        });
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
      getHomeBanner().then((res) => {
        this.bannerPic = res.data;
      });
      getMessageList().then((res) => {
        // console.log(res, '111');
        this.title = res.data[0]
      });
    },
  };
</script>
<style scoped lang="scss">
  .home {
    background: #181a20;
    font-size: 14px;
    min-height: 100%;
    padding: 30px 18.5px;
    font-family: "pingfang";
    overflow: hidden;
    .boxss {
      // width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 50px;
      background-color: rgba(55, 159, 0, 1);
      border-radius: 20px;
      padding: 10px;
      margin: 10px;
      display: flex;
      color: white;
      font-size: 32px;
    }

    .shopbox {
      border: 1px rgba(237, 237, 237, 1) solid;
      border-radius: 10px;
      margin: 32px 12px 12px 12px;
      padding: 10px;
      width: 100%;
    }

    .top-nav {
      height: 30px;
      line-height: 30px;

      span {
        font-size: 28px;
        font-weight: 700;
      }

      img {
        margin-right: 10px;
        left: 4.72px;
        top: 2.64px;
        width: 24.56px;
        height: 28.72px;
        opacity: 1;
        background: rgba(18, 18, 18, 1);
      }

      .right {
        img {
          left: 624.5px;
          top: 70px;
          width: 34px;
          height: 34px;
          opacity: 1;
        }
      }
    }

    .search-for {
      position: relative;
      margin: 20px auto;

      input {
        text-indent: 16px;
        width: 690px;
        height: 60px;
        border-radius: 205px;
        background: rgba(255, 255, 255, 1);
        border: 2px solid rgba(240, 240, 240, 1);
      }

      button {
        width: 110px;
        height: 46px;
        border-radius: 205px;
        background: linear-gradient(90deg,
            rgba(151, 202, 114, 1) 0%,
            rgba(90, 194, 104, 1) 100%);
        color: #fff;
        border: none;
        position: absolute;
        top: 11px;
        right: 30px;
        font-size: 24px;
      }
    }

    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
      border-radius: 20px;

      img {
        width: 690px;
        height: 301px;
        border-radius: 10px;
      }
    }

    .module {
      flex-wrap: wrap;

      >div {
        width: 25%;
        margin-bottom: 40px;

        img {
          left: 39px;
          top: 572px;
          height: 68px;
          margin-bottom: 18.5px;
        }
      }
    }

    .shop {
      .hot-shop {
        overflow-x: scroll;

        >div {
          box-sizing: border-box;
          padding: 20px;
          width: 260px;
          height: 330px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(240, 240, 240, 1);

          img {
            width: 220px;
            height: 220px;
          }
        }
      }
    }

    .recommend-shop {
      margin-bottom: 50px;

      .recommend-list {
        .shop-item {
          width: 690px;
          height: 200px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(240, 240, 240, 1);
          padding: 30px 20px;

          img {
            width: 220px;
            height: 141px;
            margin-right: 20px;
          }

          .green {
            color: rgba(90, 194, 104, 1);
          }

          span {
            border: 1px solid rgba(90, 194, 104, 1);
            margin-right: 20px;
            border-radius: 12px;
            display: inline-block;
            box-sizing: border-box;
            padding: 5px 7.5px;
          }
        }
      }
    }

    .left-active {
      border-left: 5px solid rgba(90, 194, 104, 1);
      padding-left: 12px;
    }

    .spanbgm {
      background: rgba(222, 90, 91, 1);
      border-radius: 6px;
      color: white;
      padding: 5px;
      font-size: 20px;
    }

    .imgsizesty {
      width: 100px;
      height: 85px;
    }

    .van-col--25 {
      width: 30%;
      margin-right: 22px;
    }

    .van-col--28 {
      width: 30%;
    }

    .van-col--26 {
      width: 63.666667%;
      margin-right: 22px;
    }
  }

  .spansty {
    font-size: 22px;
    color: #B3B4B7;
  }

  .percentagesty {
    width: 142px;
    height: 59px;
    background: #58BD7D;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    color: white;
    font-size: 28px;
  }

  .percentagestytwo {
    width: 142px;
    height: 59px;
    background: #D20E0E;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    color: white;
    font-size: 28px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #292C35;
    border-radius: 0px 0px 0px 0px;
    opacity: 0.1;
    margin: 15px 0;
  }
</style>
<style lang="scss">
  .noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
  }

  .van-tab {
    line-height: normal !important;
    font-size: 32px !important;
    font-weight: bold !important;
  }

  .van-tab--active {
    color: rgba(55, 159, 0, 1) !important;
    font-size: 32px !important;
    font-weight: bold !important;
  }

  .van-tabs__line {
    background: rgba(55, 159, 0, 1) !important;
  }

  .van-tab__text--ellipsis {
    font-size: 28px !important;
  }
</style>
